// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column; /* Flexbox para coluna */
    flex: 1 1; /* Garante que o contêiner ocupe todo o espaço disponível */
  /*  justify-content: center; /* Centraliza o conteúdo verticalmente */
  /*  align-items: center; /* Centraliza o conteúdo horizontalmente */
    padding: 20px; /* Espaçamento interno */
  }
  
  .card {
    border-radius: 8px; /* Bordas arredondadas para os cards */
  }
  
  .card-body {
    padding: 10px; /* Espaçamento interno do card */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ubs/home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB,EAAE,wBAAwB;IAChD,SAAO,EAAE,2DAA2D;EACtE,qEAAqE;EACrE,mEAAmE;IACjE,aAAa,EAAE,wBAAwB;EACzC;;EAEA;IACE,kBAAkB,EAAE,sCAAsC;EAC5D;;EAEA;IACE,aAAa,EAAE,gCAAgC;EACjD","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column; /* Flexbox para coluna */\n    flex: 1; /* Garante que o contêiner ocupe todo o espaço disponível */\n  /*  justify-content: center; /* Centraliza o conteúdo verticalmente */\n  /*  align-items: center; /* Centraliza o conteúdo horizontalmente */\n    padding: 20px; /* Espaçamento interno */\n  }\n  \n  .card {\n    border-radius: 8px; /* Bordas arredondadas para os cards */\n  }\n  \n  .card-body {\n    padding: 10px; /* Espaçamento interno do card */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
