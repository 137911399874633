import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/ubs/home/Home';
import Avaliacao from '../pages/ubs/avaliacao/Avaliacao';
import PrivateRoute from '../components/PrivateRoute';
import DashboardGeral from '../pages/ubs/dashboardgeral/DashboardGeral';
import Admin from '../pages/ubs/admin/Admin';
import Painel from '../pages/ubs/painel/Painel';
import StatusEnvio from '../pages/ubs/statusenviomensagem/StatusEnvioMensagem';
import Agradecimento from '../pages/ubs/agradecimento/Agradecimento';


function UBSRoutes() {
    return (
        <Routes>
            <Route path="/avaliacao" element={<Avaliacao />} />
            <Route path="/agradecimento" element={<Agradecimento />} />

            {/* Usando PrivateRoute para proteger as rotas */}

            <Route
                path="/home"
                element={
                    <PrivateRoute roles={['func_saude','admin','sec_saude']}>
                        <Home />
                    </PrivateRoute>
                }
            />


            <Route
                path="/dashboard_geral"
                element={
                    <PrivateRoute roles={['func_saude','admin','sec_saude']}>
                        <DashboardGeral />
                    </PrivateRoute>
                }
            />
            <Route
                path="/admin"
                element={
                    <PrivateRoute roles={['admin','sec_saude']}>
                        <Admin />
                    </PrivateRoute>
                }
            />
            <Route
                path="/painel"
                element={
                    <PrivateRoute roles={['admin','sec_saude','func_saude']}>
                        <Painel />
                    </PrivateRoute>
                }
            />
            <Route
                path="/status_envio"
                element={
                    <PrivateRoute roles={['admin']}>
                        <StatusEnvio />
                    </PrivateRoute>
                }
            />

        </Routes>
    );
}

export default UBSRoutes;

