import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/educacao/home/Home';
import Avaliacao from '../pages/educacao/avaliacao/Avaliacao';
import PrivateRoute from '../components/PrivateRoute';
import DashboardMatricula from '../pages/educacao/dashboardmatricula/DashBoardMatricula';
import DashboardCPA from '../pages/educacao/dashboardcpa/DashBoardCPA';
import Matricula from '../pages/educacao/matricula/Matricula';
import Agradecimento from '../pages/ubs/agradecimento/Agradecimento';
import GerarCPA from '../pages/educacao/gerarcpa/GerarCPA';



function EducacaoRoutes() {
    return (
        <Routes>
            <Route path="/avaliacao" element={<Avaliacao />} />
            <Route path="/agradecimento" element={<Agradecimento />} />

            {/* Usando PrivateRoute para proteger as rotas */}

            <Route
                path="/home"
                element={
                    <PrivateRoute roles={['func_educ','sec_educ','admin']}>
                        <Home />
                    </PrivateRoute>
                }
            />


            <Route
                path="/dashboard_matricula"
                element={
                    <PrivateRoute roles={['sec_educ','admin']}>
                        <DashboardMatricula />
                    </PrivateRoute>
                }
            />
            <Route
                path="/dashboard_cpa"
                element={
                    <PrivateRoute roles={['admin','sec_educ']}>
                        <DashboardCPA />
                    </PrivateRoute>
                }
            />
            <Route
                path="/matricula"
                element={
                    <PrivateRoute roles={['func_educ','sec_educ','admin']}>
                        <Matricula />
                    </PrivateRoute>
                }
            />

            <Route
                path="/gerar_cpa"
                element={
                    <PrivateRoute roles={['func_educ','sec_educ','admin']}>
                        <GerarCPA />
                    </PrivateRoute>
                }
            />

        </Routes>
    );
}

export default EducacaoRoutes;

