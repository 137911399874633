// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selector-page {
    padding: 50px 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
  }
  
  .selector-card {
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .selector-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .selector-card .text-primary {
    color: #0d6efd !important;
  }
  
  .selector-card .text-secondary {
    color: #308d4f !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/selector/SelectorPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,2CAA2C;IAC3C,qDAAqD;EACvD;;EAEA;IACE,sBAAsB;IACtB,4CAA4C;EAC9C;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".selector-page {\n    padding: 50px 20px;\n    background-color: #f8f9fa;\n    min-height: 100vh;\n  }\n  \n  .selector-card {\n    border: none;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s ease, box-shadow 0.2s ease;\n  }\n  \n  .selector-card:hover {\n    transform: scale(1.05);\n    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);\n  }\n  \n  .selector-card .text-primary {\n    color: #0d6efd !important;\n  }\n  \n  .selector-card .text-secondary {\n    color: #308d4f !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
