import React from 'react';
import { useAuth } from '../../../context/AuthContext';
import { Container, Card, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FcComboChart } from "react-icons/fc";
import { FcSms } from "react-icons/fc";
import { FcBusinessman } from "react-icons/fc";
import { FcAnswers } from "react-icons/fc";


import sistemaImage from '../../../assets/home-image.png'; // Ajuste o caminho conforme necessário
import { FcVoicePresentation } from "react-icons/fc";
import './Home.css'; // Importa o CSS específico

const Home = () => {
  const { user } = useAuth();

  // hasRole define se o botão referente será renderizado de acordo coma lista de roles definidas
  const hasRole = (...roles) => roles.some(role => user?.roles?.includes(role));

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Bem-vindo ao Sistema</h2>
      <Image 
        src={sistemaImage} // Caminho para sua imagem
        fluid // Para torná-la responsiva
        className="mb-4 mx-auto" // Adiciona margens e centraliza
        style={{ maxWidth: '40%', height: 'auto' }} // Ajusta o tamanho da imagem
        alt="Descrição do sistema" 
      />
      <Row className="mt-4">
        {hasRole('sec_educ','admin','func_educ') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcVoicePresentation size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Cadastro de matrícula</Card.Title>
                <Link to="/educacao/matricula" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}
        {hasRole('admin','sec_educ','func_educ') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcBusinessman size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Dashboard CPA administrativo</Card.Title>
                <Link to="/educacao/dashboard_cpa" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}
        {hasRole('sec_educ','admin') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcComboChart size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Dashboard Matricula</Card.Title>
                <Link to="/educacao/dashboard_matricula" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}

        {hasRole('sec_educ','admin','func_educ') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcAnswers size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Gerar avaliações CPA</Card.Title>
                <Link to="/educacao/gerar_cpa" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Home;
