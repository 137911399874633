import React, { useState, useEffect } from 'react';
import api from '../../../services/Api';


const StatusEnvioMensagens = () => {
  const [iframeUrl, setIframeUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Para exibir erros, se necessário

  useEffect(() => {
    const fetchIframeUrl = async () => {
      try {
        const response = await api.get('/protected/metabase/url/admin', {
          params: { dashboardId: process.env.REACT_APP_ID_DASHBOARD_ENVIOS }, // Id do dashboard no metabase
        });
        setIframeUrl(response.data.iframeUrl); // Atualiza o URL do iframe
      } catch (error) {
        console.error('Erro ao obter o iframe URL:', error);
        setErrorMessage('Erro ao carregar o dashboard. Tente novamente mais tarde.');
      }
    };

    fetchIframeUrl();
  }, []);

  return (
    <div style={{ height: '100vh', margin: 0, padding: 0, display: 'flex', flexDirection: 'column' }}>
      {errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        iframeUrl && (
          <iframe
            src={iframeUrl}
            style={{ flex: 1, width: '100%', border: 'none' }}
            frameBorder="0"
            title="Dashboard"
          />
        )
      )}
    </div>
  );
};


export default StatusEnvioMensagens;
