import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Container, Row, Col, Form, Image, Modal } from 'react-bootstrap';
import api from '../../../services/Api';
import painelImage from '../../../assets/home-image.png'; // Atualize o caminho conforme necessário

const Matricula = () => {
  const { register, handleSubmit, setValue, reset } = useForm();
  const [buscaCpfResponsavel, setBuscaCpfResponsavel] = useState('');
  const [buscaCpfAluno, setBuscaCpfAluno] = useState('');
  const [responsavelEncontrado, setResponsavelEncontrado] = useState(false);
  const [alunoEncontrado, setAlunoEncontrado] = useState(false);
  const [escolas, setEscolas] = useState([]);
  const [series, setSeries] = useState([]);
  const [showModalResponsavel, setShowModalResponsavel] = useState(false);
  const [showModalAluno, setShowModalAluno] = useState(false);

  useEffect(() => {
    const fetchEscolas = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/escolas`);
        setEscolas(response.data);
      } catch (error) {
        console.error('Erro ao buscar escolas:', error);
      }
    };

    const fetchSeries = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/series`);
        setSeries(response.data);
      } catch (error) {
        console.error('Erro ao buscar séries:', error);
      }
    };

    fetchEscolas();
    fetchSeries();
  }, []);

  const handleBuscaCpfResponsavel = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/responsaveis/${buscaCpfResponsavel}`);
      const responsavel = response.data;
      if (responsavel) {
        setResponsavelEncontrado(true);
        setValue('nomeResponsavel', responsavel.nome);
        setValue('cpfResponsavel', responsavel.cpf);
        setValue('rgResponsavel', responsavel.rg);
        setValue('telefoneResponsavel', responsavel.telefone);
        setValue('emailResponsavel', responsavel.email);
      } else {
        setResponsavelEncontrado(false);
        setShowModalResponsavel(true);
      }
    } catch (error) {
      console.error('Erro ao buscar responsável:', error);
      setResponsavelEncontrado(false);
      setShowModalResponsavel(true);
    }
  };

  const handleBuscaCpfAluno = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/alunos/${buscaCpfAluno}`);
      const aluno = response.data;
      if (aluno) {
        setAlunoEncontrado(true);
        setValue('nomeAluno', aluno.nome);
        setValue('cpfAluno', aluno.cpf);
        setValue('dataNascimentoAluno', aluno.data_nascimento.split('T')[0]);
        setValue('telefoneAluno', aluno.telefone);
        setValue('emailAluno', aluno.email);
      } else {
        setAlunoEncontrado(false);
        setShowModalAluno(true);
      }
    } catch (error) {
      console.error('Erro ao buscar aluno:', error);
      setAlunoEncontrado(false);
      setShowModalAluno(true);
    }
  };

  const onSubmit = async (data) => {
    const dadosParaEnvio = {
      responsavel: {
        nome: data.nomeResponsavel,
        cpf: data.cpfResponsavel,
        rg: data.rgResponsavel,
        telefone: data.telefoneResponsavel,
        email: data.emailResponsavel,
      },
      aluno: {
        nome: data.nomeAluno,
        cpf: data.cpfAluno,
        data_nascimento: data.dataNascimentoAluno,
        telefone: data.telefoneAluno,
        email: data.emailAluno,
      },
      escola_id: data.escola,
      serie_id: data.serie,
      ano_letivo: new Date().getFullYear(),
    };

    try {
      const response = await api.post('/educacao/matriculas', dadosParaEnvio);
      alert('Matrícula realizada com sucesso!');
      reset();
    } catch (error) {
      console.error('Erro ao realizar matrícula:', error);
      alert('Erro ao realizar matrícula. Tente novamente.');
    }
  };

  const handleCadastroResponsavel = async (data) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/responsaveis`, data);
      alert(response.data.message);
      setShowModalResponsavel(false);
      setResponsavelEncontrado(true);
      setValue('nomeResponsavel', data.nome);
      setValue('cpfResponsavel', data.cpf);
      setValue('rgResponsavel', data.rg);
      setValue('telefoneResponsavel', data.telefone);
      setValue('emailResponsavel', data.email);
    } catch (error) {
      console.error('Erro ao cadastrar responsável:', error);
      alert('Erro ao cadastrar responsável. Tente novamente.');
    }
  };

  const handleCadastroAluno = async (data) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/alunos`, data);
      alert(response.data.message);
      setShowModalAluno(false);
      setAlunoEncontrado(true);
      setValue('nomeAluno', data.nome);
      setValue('cpfAluno', data.cpf);
      setValue('dataNascimentoAluno', data.data_nascimento);
      setValue('telefoneAluno', data.telefone);
      setValue('emailAluno', data.email);
    } catch (error) {
      console.error('Erro ao cadastrar aluno:', error);
      alert('Erro ao cadastrar aluno. Tente novamente.');
    }
  };

  return (
    <Container>
      <Image
        src={painelImage}
        fluid
        className="mb-4 mx-auto d-block"
        style={{ maxWidth: '40%', height: 'auto' }}
        alt="Descrição do sistema"
      />

      <h2>Matrícula de Aluno</h2>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Seção Responsável */}
        <fieldset>
          <legend>Dados do Responsável</legend>
          <hr className="my-4" />
          <Row className="mb-3">
            <Col md={8}>
              <Form.Group>
                <Form.Label>Buscar Responsável por CPF</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o CPF do Responsável"
                  value={buscaCpfResponsavel}
                  onChange={(e) => setBuscaCpfResponsavel(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="d-flex align-items-end">
              <Button onClick={handleBuscaCpfResponsavel}>Buscar</Button>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Nome do Responsável</Form.Label>
                <Form.Control
                  type="text"
                  {...register('nomeResponsavel')}
                  disabled={responsavelEncontrado}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>CPF do Responsável</Form.Label>
                <Form.Control
                  type="text"
                  {...register('cpfResponsavel')}
                  disabled={responsavelEncontrado}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>RG do Responsável</Form.Label>
                <Form.Control
                  type="text"
                  {...register('rgResponsavel')}
                  disabled={responsavelEncontrado}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Telefone do Responsável</Form.Label>
                <Form.Control
                  type="text"
                  {...register('telefoneResponsavel')}
                  disabled={responsavelEncontrado}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email do Responsável</Form.Label>
                <Form.Control
                  type="email"
                  {...register('emailResponsavel')}
                  disabled={responsavelEncontrado}
                />
              </Form.Group>
            </Col>
          </Row>
        </fieldset>

        {/* Seção Aluno */}
        <fieldset className="mt-5">
          <legend>Dados do Aluno</legend>
          <hr className="my-4" />
          <Row className="mb-3">
            <Col md={8}>
              <Form.Group>
                <Form.Label>Buscar Aluno por CPF</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o CPF do Aluno"
                  value={buscaCpfAluno}
                  onChange={(e) => setBuscaCpfAluno(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="d-flex align-items-end">
              <Button onClick={handleBuscaCpfAluno}>Buscar</Button>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Nome do Aluno</Form.Label>
                <Form.Control
                  type="text"
                  {...register('nomeAluno')}
                  disabled={alunoEncontrado}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>CPF do Aluno</Form.Label>
                <Form.Control
                  type="text"
                  {...register('cpfAluno')}
                  disabled={alunoEncontrado}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Data de Nascimento</Form.Label>
                <Form.Control
                  type="date"
                  {...register('dataNascimentoAluno')}
                  disabled={alunoEncontrado}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Telefone do Aluno</Form.Label>
                <Form.Control
                  type="text"
                  {...register('telefoneAluno')}
                  disabled={alunoEncontrado}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email do Aluno</Form.Label>
                <Form.Control
                  type="email"
                  {...register('emailAluno')}
                  disabled={alunoEncontrado}
                />
              </Form.Group>
            </Col>
          </Row>
        </fieldset>

        {/* Seção Escola */}
        <fieldset className="mt-5">
          <legend>Dados da Escola</legend>
          <hr className="my-4" />
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Escola</Form.Label>
                <Form.Select {...register('escola')} defaultValue="">
                  <option value="" disabled>Selecione a escola</option>
                  {escolas.map((escola) => (
                    <option key={escola.id} value={escola.id}>{escola.nome}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Série</Form.Label>
                <Form.Select {...register('serie')} defaultValue="">
                  <option value="" disabled>Selecione a série</option>
                  {series.map((serie) => (
                    <option key={serie.id} value={serie.id}>{serie.nome}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </fieldset>

        <Row className="mt-4">
          <Col>
            <Button type="submit" variant="primary">Realizar Matrícula</Button>
          </Col>
          </Row>
      </Form>

      {/* Modal de Cadastro de Responsável */}
      <Modal show={showModalResponsavel} onHide={() => setShowModalResponsavel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Responsável</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handleCadastroResponsavel)}>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control type="text" {...register('nome')} placeholder="Nome do Responsável" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>CPF</Form.Label>
              <Form.Control type="text" {...register('cpf')} placeholder="CPF do Responsável" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>RG</Form.Label>
              <Form.Control type="text" {...register('rg')} placeholder="RG do Responsável" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Telefone</Form.Label>
              <Form.Control type="text" {...register('telefone')} placeholder="Telefone do Responsável" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" {...register('email')} placeholder="Email do Responsável" required />
            </Form.Group>
            <Button variant="primary" type="submit">Cadastrar</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal de Cadastro de Aluno */}
      <Modal show={showModalAluno} onHide={() => setShowModalAluno(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Aluno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handleCadastroAluno)}>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control type="text" {...register('nome')} placeholder="Nome do Aluno" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>CPF</Form.Label>
              <Form.Control type="text" {...register('cpf')} placeholder="CPF do Aluno" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Data de Nascimento</Form.Label>
              <Form.Control type="date" {...register('data_nascimento')} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Telefone</Form.Label>
              <Form.Control type="text" {...register('telefone')} placeholder="Telefone do Aluno" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" {...register('email')} placeholder="Email do Aluno" required />
            </Form.Group>
            <Button variant="primary" type="submit">Cadastrar</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Matricula;
