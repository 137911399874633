import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import api from '../../../services/Api';

const GerarPdf = () => {
  const [escolas, setEscolas] = useState([]);
  const [escolaSelecionada, setEscolaSelecionada] = useState('');
  const [mensagem, setMensagem] = useState('');

  useEffect(() => {
    const fetchEscolas = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/escolas`);
        setEscolas(response.data);
      } catch (error) {
        console.error('Erro ao buscar escolas:', error);
        setMensagem('Erro ao buscar escolas. Tente novamente mais tarde.');
      }
    };

    fetchEscolas();
  }, []);

  const gerarPdf = async () => {
    if (!escolaSelecionada) {
      setMensagem('Por favor, selecione uma escola.');
      return;
    }

    try {
        const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/avaliacoes/pendentes-qrcode-pdf?id_escola=${escolaSelecionada}`, {
        responseType: 'blob', // Para lidar com arquivos PDF
      });

      // Criação de um URL para download do PDF
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'avaliacoes_pendentes.pdf'); // Nome do arquivo para download
      document.body.appendChild(link);
      link.click();
      link.remove();
      setMensagem('PDF gerado com sucesso!');
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
      setMensagem('Erro ao gerar PDF. Tente novamente.');
    }
  };

  return (
    <Container>
      <h2>Gerar PDF de Avaliações CPA</h2>

      {mensagem && <Alert variant="info">{mensagem}</Alert>}

      <Row className="mb-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Selecione a Escola</Form.Label>
            <Form.Select value={escolaSelecionada} onChange={(e) => setEscolaSelecionada(e.target.value)}>
              <option value="">Escolha uma escola</option>
              {escolas.map((escola) => (
                <option key={escola.id} value={escola.id}>{escola.nome}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button onClick={gerarPdf} variant="primary">Gerar PDF</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default GerarPdf;
