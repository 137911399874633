import React, { useState, useEffect } from "react"; 
import { useSearchParams, useNavigate } from "react-router-dom";
import { Form, Button, Container, Alert, Image, Row, Col } from "react-bootstrap";
import axios from "axios";
import { FaStar, FaRegStar } from 'react-icons/fa';
import agradecimentoImage from '../../../assets/agradecimento.png';

const PaginaAvaliacao = () => {
  const [searchParams] = useSearchParams();
  const avaliacao_id = searchParams.get("avaliacao_id");
  const navigate = useNavigate();

  const [ratings, setRatings] = useState({
    qualidade_ensino: 0,
    infraestrutura: 0,
    relacionamento_professores: 0,
    seguranca: 0,
    material: 0,
    limpeza: 0,
  });
  const [feedback, setFeedback] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const verificarStatusAvaliacao = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/status_avaliacao?avaliacao_id=${avaliacao_id}`
        );
        if (response.data.status === "RESPONDIDO") {
          alert("Essa avaliação já foi respondida. Você será redirecionado.");
          navigate("/agradecimento");
        }
      } catch (error) {
        console.error("Erro ao verificar status da avaliação:", error);
        setErrorMessage("Houve um problema ao verificar o status da avaliação.");
      }
    };

    if (avaliacao_id) {
      verificarStatusAvaliacao();
    } else {
      setErrorMessage("ID da avaliação não encontrado.");
    }
  }, [avaliacao_id, navigate]);

  const enviarAvaliacao = async (e) => {
    e.preventDefault();
    const dadosAvaliacao = {
      qualidade_ensino: ratings.qualidade_ensino,
      infraestrutura: ratings.infraestrutura,
      relacionamento_professores: ratings.relacionamento_professores,
      seguranca: ratings.seguranca,
      material: ratings.material,
      limpeza: ratings.limpeza,
      comentario: feedback,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/educacao/avaliacao?avaliacao_id=${avaliacao_id}`,
        dadosAvaliacao
      );

      if (response.data.error) {
        setErrorMessage(response.data.error);
        alert(response.data.error);
        navigate("/agradecimento");
        return;
      }

      setSuccessMessage("Avaliação enviada com sucesso! Obrigado pelo feedback.");
      navigate("/agradecimento");
    } catch (error) {
      console.error("Erro ao enviar avaliação:", error);
      const errorMessageToShow = error.response?.data?.error || "Houve um erro ao enviar sua avaliação.";
      alert(errorMessageToShow);
      navigate("/agradecimento");
    }
  };

  const renderStars = (field) => {
    return Array.from({ length: 5 }, (_, index) => {
      const starValue = index + 1;
      return (
        <span
          key={starValue}
          onClick={() => setRatings(prev => ({ ...prev, [field]: starValue }))}
          style={{ cursor: "pointer", fontSize: "35px" }}
        >
          {starValue <= ratings[field] ? (
            <FaStar color="#FFD700" />
          ) : (
            <FaRegStar color="#ccc" />
          )}
        </span>
      );
    });
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Avalie o Atendimento</h2>

      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Image
        src={agradecimentoImage}
        fluid
        className="mb-4 mx-auto"
        style={{ maxWidth: '30%', height: 'auto' }}
        alt="Descrição do sistema"
      />

      <Form onSubmit={enviarAvaliacao}>
        <Row>
          <Col md={6} className="text-center">
            <Form.Group controlId="formQualidadeEnsino" className="mb-4">
              <Form.Label>Qualidade do Ensino {`👨‍🎓`}</Form.Label>
              <div>{renderStars('qualidade_ensino')}</div>
            </Form.Group>

            <Form.Group controlId="formInfraestrutura" className="mb-4">
              <Form.Label>Infraestrutura {`🏫`}</Form.Label>
              <div>{renderStars('infraestrutura')}</div>
            </Form.Group>

            <Form.Group controlId="formRelacionamentoProfessores" className="mb-4">
              <Form.Label>Relacionamento com Professores {`👨‍🏫`}</Form.Label>
              <div>{renderStars('relacionamento_professores')}</div>
            </Form.Group>
          </Col>

          <Col md={6} className="text-center">
            <Form.Group controlId="formSeguranca" className="mb-4">
              <Form.Label>Segurança {`👮‍♂️`}</Form.Label>
              <div>{renderStars('seguranca')}</div>
            </Form.Group>

            <Form.Group controlId="formMaterial" className="mb-4">
              <Form.Label>Material Didático {`📚`}</Form.Label>
              <div>{renderStars('material')}</div>
            </Form.Group>

            <Form.Group controlId="formLimpeza" className="mb-4">
              <Form.Label>Limpeza {`🗑️`}</Form.Label>
              <div>{renderStars('limpeza')}</div>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formFeedback" className="mt-4">
          <Form.Label>Deixe elogio, sugestão ou crítica (opcional):</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </Form.Group>

        <div className="text-center mt-4">
          <Button variant="primary" type="submit">
            Enviar Avaliação
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default PaginaAvaliacao;
