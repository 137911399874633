import React, { useEffect, useState } from 'react'; 
import api from '../../../services/Api'; 
import './DashboardGeral.css'; // Importa o CSS para as transições

const DashboardGeral = () => {
  const [iframeUrl, setIframeUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Para exibir erros, se necessário
  const [currentIndex, setCurrentIndex] = useState(0); // Índice do dashboard atual
  const [dashboards, setDashboards] = useState([
    { id: process.env.REACT_APP_ID_DASHBOARD_GERAL1, name: 'Dashboard Geral 1' },
    { id: process.env.REACT_APP_ID_DASHBOARD_GERAL2, name: 'Dashboard Geral 2' },
    { id: process.env.REACT_APP_ID_DASHBOARD_GERAL3, name: 'Dashboard Geral 3' },
    // Adicione mais dashboards conforme necessário
  ]);

  useEffect(() => {
    const fetchIframeUrl = async (dashboardId) => {
      try {
        const response = await api.get('/protected/metabase/url/geral', {
          params: { dashboardId }, // Usa o ID do dashboard selecionado
        });
        setIframeUrl(response.data.iframeUrl); // Atualiza o URL do iframe
      } catch (error) {
        console.error('Erro ao obter o iframe URL:', error);
        setErrorMessage('Erro ao carregar o dashboard. Tente novamente mais tarde.');
      }
    };

    // Carrega o URL do primeiro dashboard
    fetchIframeUrl(dashboards[currentIndex].id);

    // Intervalo para mudar o dashboard a cada 15 segundos
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % dashboards.length;
        fetchIframeUrl(dashboards[nextIndex].id); // Atualiza a URL do iframe
        return nextIndex;
      });
    }, 15000);

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [dashboards, currentIndex]); // Atualiza sempre que o currentIndex mudar

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        iframeUrl && (
          <iframe
            src={iframeUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Dashboard"
            style={{ flex: 1, border: 'none' }}
          />
        )
      )}
    </div>
  );
};

export default DashboardGeral;
