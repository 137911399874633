import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Necessário para redirecionar dentro do app
import api from '../../../services/Api';

const ResultadosAdministrativos = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Hook para navegar no React Router

  useEffect(() => {
    const fetchIframeUrl = async () => {
      try {
        const response = await api.get('/protected/metabase/url/admin', {
          params: { dashboardId: process.env.REACT_APP_ID_DASHBOARD_ADMINISTRATIVO },
        });

        // Salva o histórico de navegação antes de redirecionar
        navigate('/home'); // Garante que o botão "Voltar" leve para a página Home
        window.location.href = response.data.iframeUrl; // Redireciona para o iframe
      } catch (error) {
        console.error('Erro ao obter o iframe URL:', error);
        setErrorMessage('Erro ao carregar o dashboard. Tente novamente mais tarde.');
      }
    };

    fetchIframeUrl();
  }, [navigate]);

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default ResultadosAdministrativos;
