import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import SelectorPage from './pages/selector/SelectorPage'; // Página inicial com o seletor
import Header from './components/Header';
import Footer from './components/Footer';
import UBSRoutes from './routes/UBSRoutes'; // Rotas específicas da UBS
import EducacaoRoutes from './routes/EducacaoRoutes'; // Rotas específicas da Educação
import PrivateRoute from './components/PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import Login from './pages/login/Login';


function AppContent() {
  const location = useLocation(); // Hook para pegar a rota atual
  const hideHeaderRoutes = ["/login",
     "/educacao/avaliacao", "/educacao/agradecimento",
    "/ubs/avaliacao","/ubs/agradecimento"]; // Rotas que não devem exibir o Header
  const hideFooterRoutes = ["/admin", "/status_envio", "/dashboard_geral"]; // Rotas que ocultam o Footer
  const currentPath = location.pathname;



  return (
    <div className="app-content">
      {/* Renderiza o Header apenas se a rota atual não estiver em hideHeaderRoutes */}
      {!hideHeaderRoutes.includes(currentPath) && <Header />}

      <Routes>
        {/* Página de Login */}
        <Route path="/login" element={<Login />} />

        {/* Redireciona para login se não estiver autenticado
        {!isAuthenticated && <Route path="*" element={<Navigate to="/login" replace />} />} */}

        {/* Página inicial com o seletor */}
        <Route
          path="/setores"
          element={
            <PrivateRoute roles={['user', 'admin']}>
              <SelectorPage />
            </PrivateRoute>
          }
        />


        {/* Rotas da UBS */}
        <Route
          path="/ubs/*"
          element={
              <UBSRoutes />
          }
        />

        {/* Rotas da Educação */}
        <Route
          path="/educacao/*"
          element={
              <EducacaoRoutes />
          }
        />
      </Routes>
      {/* Renderiza o Footer apenas se a rota atual não estiver em hideFooterRoutes */}
      {!hideFooterRoutes.includes(currentPath) && <Footer />}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
