import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/as-logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem('munitec_refreshToken'),
        }),
      });

      if (response.ok) {
        logout();
        navigate('/'); // Redireciona para a página de login
      } else {
        console.error('Erro ao fazer logout');
      }
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const handleHomeClick = () => {
    navigate('/setores'); // Redireciona para a página do SelectorPage
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <div className="d-flex align-items-center">
          {/* Brand */}
          <Navbar.Brand href="/setores">Munitec</Navbar.Brand>
          {/* O brand também ta dierecionando para avaliação de serviços atualmente */}

          {/* Botão Home ao lado do Brand */}
          <Button
            variant="outline-light"
            className="ms-3"
            onClick={handleHomeClick}
          >
            Home
          </Button>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            {/* Texto de Boas-vindas */}
            <Navbar.Text className="me-3">
              Bem-vindo, <strong>{user ? user.name : 'Usuário'}</strong>
            </Navbar.Text>

            {/* Botão de Logout */}
            {user ? (
              <Button variant="outline-light" onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button variant="outline-light" href="/login">
                Login
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;