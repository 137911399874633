import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { FaHospitalAlt, FaBook } from 'react-icons/fa'; // Ícones

import './SelectorPage.css'; // Estilos específicos da página

function SelectorPage() {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Define se o botão referente será renderizado com base nas roles do usuário
  const hasRole = (...roles) => roles.some(role => user?.roles?.includes(role));

  const handleSelection = (menu) => {
    if (menu === 'ubs') {
      navigate('/ubs/home');
    } else if (menu === 'educacao') {
      navigate('/educacao/home');
    }
  };

  return (
    <Container className="selector-page text-center">
      <h1 className="mb-4">Bem-vindo!</h1>
      <p className="mb-5">Escolha o setor que deseja acessar:</p>
      <Row className="justify-content-center">
        {hasRole('sec_saude','func_saude','admin') && (
          <Col xs={12} md={5} className="mb-4">
            <Card className="selector-card">
              <Card.Body>
                <FaHospitalAlt size={50} className="mb-3 text-primary" />
                <Card.Title>Unidade Básica de Saúde (UBS)</Card.Title>
                <Card.Text>Acesse informações e serviços relacionados às UBS.</Card.Text>
                <Button variant="primary" onClick={() => handleSelection('ubs')}>
                  Entrar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
        {hasRole('sec_educ','func_educ','admin') && (
          <Col xs={12} md={5}>
            <Card className="selector-card">
              <Card.Body>
                <FaBook size={50} className="mb-3 text-secondary" />
                <Card.Title>Educação</Card.Title>
                <Card.Text>Acesse recursos e informações do setor de Educação.</Card.Text>
                <Button variant="secondary" onClick={() => handleSelection('educacao')}>
                  Entrar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default SelectorPage;
