import React, { useState, useEffect } from 'react'; 
import { useForm } from 'react-hook-form';
import { Button, Container, Row, Col, Form, Alert, Image } from 'react-bootstrap';
import api from '../../../services/Api'; // Substitua pelo caminho correto do arquivo
import painelImage from '../../../assets/home-image.png'; // Substitua pelo caminho correto
import { useAuth } from '../../../context/AuthContext'; // Caminho correto do arquivo de contexto

const Painel = () => {
  const { user } = useAuth(); // captura o nome do usuário logado
  if (!user) {
    console.error("Usuário não encontrado no contexto!");
  }
  const { register, handleSubmit, setValue, reset } = useForm();
  const [buscaCpf, setBuscaCpf] = useState('');
  const [pacienteNaoEncontrado, setPacienteNaoEncontrado] = useState(false);
  const [locaisAtendimento, setLocaisAtendimento] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [selectedLocalId, setSelectedLocalId] = useState(null);

  useEffect(() => {
    // Busca locais de atendimento na API
    const fetchLocaisAtendimento = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/unidades`); // Endpoint para locais de atendimento
        setLocaisAtendimento(response.data);
      } catch (error) {
        console.error('Erro ao buscar locais de atendimento:', error);
      }
    };

    fetchLocaisAtendimento();
  }, []);

  const handleLocalChange = async (localId) => {
    setSelectedLocalId(localId);
    if (!localId) return;

    // Busca profissionais associados ao local
    try {
      const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/profissionais/${localId}`); // Endpoint para profissionais
      setProfissionais(response.data);
    } catch (error) {
      console.error('Erro ao buscar profissionais:', error);
    }
  };

  const handleBuscaCpf = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/pacientes/${buscaCpf}`); // Endpoint para buscar paciente
      const paciente = response.data[0]; // Considerando que a resposta seja um array

      if (paciente) {
        setPacienteNaoEncontrado(false);
        setValue('nome', paciente.no_cidadao || '');
        setValue('email', paciente.ds_email || '');
        setValue('celular', paciente.nu_telefone_celular || '');
        setValue('dataNascimento', paciente.dt_nascimento ? paciente.dt_nascimento.split('T')[0] : '');
      } else {
        setPacienteNaoEncontrado(true);
        reset(); // Limpa os campos
      }
    } catch (error) {
      console.error('Erro ao buscar paciente:', error);
      setPacienteNaoEncontrado(true);
      reset(); // Limpa os campos do formulário
    }
  };

  const onSubmit = async (data) => {
    // Adiciona os campos adicionais ao objeto de dados
    const dadosParaEnvio = {
      no_cidadao: data.nome,
      ds_email: data.email,
      nu_telefone_celular: data.celular,
      dt_nascimento: data.dataNascimento,
      nu_cpf: buscaCpf, // Usa o CPF que foi buscado
      id_unidade: parseInt(selectedLocalId, 10), // Converte para inteiro
      nome_unidade: locaisAtendimento.find(local => local.id_unidade === selectedLocalId)?.nome_unidade || '',
      id_profissional: parseInt(data.profissional, 10), // Converte para inteiro
      no_profissional: profissionais.find(prof => prof.id_profissional === data.profissional)?.no_profissional || '',
      no_cbo: profissionais.find(prof => prof.id_profissional === data.profissional)?.no_cbo || '',
      nome_responsavel: user?.name || 'Responsável Desconhecido' // Captura o nome do usuário logado
    };

    try {
      const response = await api.post(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/avaliacao_manual`, dadosParaEnvio);
      console.log('Dados enviados:', response.data);
      alert('Atendimento cadastrado com sucesso!'); // Notificação de sucesso
      reset(); // Limpa os campos do formulário
    } catch (error) {
      console.error('Erro ao cadastrar atendimento:', error);
      alert('Erro ao cadastrar atendimento. Tente novamente.'); // Notificação de erro
    }
  };

  return (
    <Container>
      <Image
        src={painelImage}
        fluid
        className="mb-4 mx-auto d-block"
        style={{ maxWidth: '40%', height: 'auto' }}
        alt="Descrição do sistema"
      />

      <h2>Cadastro de Atendimento</h2>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col md={8}>
            <Form.Group>
              <Form.Label>Buscar Paciente por CPF</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o CPF"
                value={buscaCpf}
                onChange={(e) => setBuscaCpf(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4} className="d-flex align-items-end">
            <Button onClick={handleBuscaCpf}>Buscar</Button>
          </Col>
        </Row>

        {pacienteNaoEncontrado && (
          <Alert variant="warning">Paciente não encontrado. Por favor, preencha os dados manualmente.</Alert>
        )}

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control {...register('nome')} type="text" placeholder="Nome" readOnly={!pacienteNaoEncontrado} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control {...register('email')} type="email" placeholder="E-mail" readOnly={false} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Telefone</Form.Label>
              <Form.Control {...register('celular')} type="text" placeholder="Telefone" readOnly={false} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Data de Nascimento</Form.Label>
              <Form.Control {...register('dataNascimento')} type="date" readOnly={!pacienteNaoEncontrado} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Local de Atendimento</Form.Label>
              <Form.Select
                {...register('localAtendimento')}
                defaultValue=""
                onChange={(e) => handleLocalChange(e.target.value)}
              >
                <option value="" disabled>
                  Selecione o local de atendimento
                </option>
                {locaisAtendimento.map((local) => (
                  <option key={local.id_unidade} value={local.id_unidade}>
                    {local.nome_unidade}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Profissional Responsável</Form.Label>
              <Form.Select {...register('profissional')} defaultValue="">
                <option value="" disabled>
                  Selecione o profissional responsável
                </option>
                {profissionais.map((profissional) => (
                  <option key={profissional.id_profissional} value={profissional.id_profissional}>
                    {profissional.no_profissional} - {profissional.no_cbo}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Button type="submit" variant="primary">
              Cadastrar Atendimento
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Painel;
